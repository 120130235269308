.annotation-container {
  pointer-events: none;
  user-select: none;

  .annotation {
    background-color: rgba(255, 255, 255, 1);
    pointer-events: auto;

    min-width: 15rem;
    border-radius: 1rem;
    border: solid 1px black;
    box-shadow: 0 0 1px 0px black inset, 0 0 2px 0px black;

    transform-origin: center center;
    transform: translate(-50%, -50%);

    &.tablet {
      min-width: 12.75rem;
      border-radius: .75rem;
    }

    &.mobile {
      min-width: 10.75rem;
      border-radius: .5rem;
    }

    .title {
      //width: 50%;
      padding: 1rem;
      display: inline-block;
      white-space: nowrap;

      font-size: 1.15rem;
      font-weight: 600;

      &.tablet {
        font-size: 1rem;
        padding: .75rem;
      }

      &.mobile {
        font-size: 0.85rem;
        padding: .5rem;
      }
    }

    .description {
      height: 0;
      color: transparent;
      padding: 0;

      white-space: normal;
      font-size: 0.8rem;
      font-weight: 400;

      &.expanded {
        height: 100%;
        min-width: 15rem;
        padding: 0 1rem 1rem 1rem;
        color: black !important;
      }

      &.tablet {
        font-size: 0.7rem;

        &.expanded {
          min-width: 12.75rem;
          padding: 0 .75rem .75rem .75rem;
        }
      }

      &.mobile {
        font-size: 0.6rem;

        &.expanded {
          min-width: 10.75rem;
          padding: 0 .5rem .5rem .5rem;
        }
      }
    }

    //transition: transform 0.1s;
  }

  .annotation-circle {
    width: 2rem;
    height: 2rem;
    position: absolute;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    //border: solid 1px red; // DEBUG

    circle {
      cx: 1rem !important;
      cy: 1rem;

      //&.tablet {
      //  width: 2rem;
      //  height: 2rem;
      //}
      //
      //&.mobile {
      //  cx: .5rem !important;
      //  cy: .5rem;
      //}
    }
  }

  .annotation-line {
    width: 500px;
    height: 500px;
    position: absolute;
    transform: translate(-50%, -50%);
    //border: solid 1px red; // DEBUG
  }
}

@media (hover: hover) and (pointer: fine) {
  .annotation-container {
    .annotation {
      &:hover {
        //background-color: blue;
        //font-weight: 700;
        cursor: pointer;
      }
    }
  }
}
