@import 'src/styles/shared';

.menu-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 50vw; // default
  z-index: 500;
}

@media (orientation: portrait) {
  .menu-button-container {
    &.desktop, &.tablet {
      width: 50vw;
    }

    &.mobile {
      width: 80vw;
    }
  }
}

@media (orientation: landscape) {
  .menu-button-container {
    &.desktop {
      width: 40vw;
    }

    &.mobile, &.tablet {
      width: 50vw;
    }
  }
}

.menu-button {
  width: 100%;
  height: 5rem;
  margin: .5rem 0 .5rem 0;

  // box
  border-radius: 1rem;
  box-shadow: none;
  border: 0;
  cursor: pointer;

  // text
  font-family: inherit;
  text-transform: uppercase;
  color: $button-text-color;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;

  background-color: $button-background-normal;
  transition: background-color 0.5s ease-out,
  transform 0.3s cubic-bezier(.1, .9, .2, 1),
  opacity 0.5s cubic-bezier(.1, .9, .2, 1) 0.1s;
  transition-duration: 0.5s;

  // cubic-bezier(.58,1.52,.51,.88)
  // cubic-bezier(.1,.9,.2,1)   =>   0.3s transform      50ms opacity       =>   ref: https://developer.microsoft.com/en-us/fluentui#/controls/web/choicegroup

  &:active {
    background-color: $button-background-active;
    transition-duration: 0s;
  }

  transform: translateY(0); // positioned correctly
  opacity: 1;

  &.offscreen-bottom {
    transform: translateY(50vh); // moved below by 100 vh
    opacity: 0;
  }

  &.tablet {
    height: 4rem;
    border-radius: 1rem;
    font-size: 2rem;
  }

  &.mobile {
    height: 2.75rem;
    border-radius: 0.5rem;
    font-size: 1.25rem;
  }
}

@media (hover: hover) and (pointer: fine) {
  .menu-button {
    &:hover {
      background-color: $button-background-hover;
      transition-duration: .2s;
    }

    &:active {
      background-color: $button-background-active;
      transition-duration: 0s;
    }
  }
}
