.loading-feedback {
  text-align: center;
  font-weight: 200;

  .circle {
    font-size: 1rem;
  }

  .text {
    font-size: 1.5rem;
  }
}