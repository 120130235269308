.menu-logo-page img {
  width: 25rem;

  &.mobile {
    width: 15rem;
  }
}

.welcome-message {
  font-size: 4rem;
  font-weight: 400;
  text-align: center;

  //-moz-text-fill-color: white;
  //-webkit-text-fill-color: white;
  //-moz-text-stroke-color: black;
  //-webkit-text-stroke-color: black;
  //-moz-text-stroke-width: 1px;
  //-webkit-text-stroke-width: 1px;

  &.mobile {
    font-size: 2rem;
  }
}

.menu-logo-ceva img {
  width: 8rem;

  &.mobile {
    width: 5rem;
  }
}
