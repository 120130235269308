@import 'src/styles/shared';

.webgl-canvas {
  //background-color: transparent;

  display: inline-block;
  position: absolute; // needed otherwise WebGL canvas doesn't shrink when reducing height
  flex-grow: 1;

  height: 100%;
  width: 100%;
  top: 0;

  //&.desktop {
  //
  //}
  //&.tablet {
  //
  //}
  //&.mobile {
  //
  //}

  transition: filter $transition-delay;
  &.blurred {
    filter: blur(10px);
  }
}

.stats, .stats > * {
  top: 0 !important;
  left: unset !important;
  right: 0 !important;
}