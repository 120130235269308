@import 'src/styles/shared';

.description {
  pointer-events: none;
  //z-index: 800 !important; // fullpage is 900

  .title {
    position: absolute;
    background-color: rgba($button-background-normal, .75);
    box-shadow: 0 0 10px 5px #fff;

    color: $button-text-color;
    font-size: 2rem;
    font-weight: 700;
    text-transform: capitalize;
    padding-left: 6rem;
    padding-right: 2.5rem;
    border-radius: 1rem;

    display: inline-flex; // not larger than content
    align-items: center;

    height: 5rem;
    margin-left: 7rem; // + 1 for logo padding
    margin-top: 4.5rem;

    //opacity: 1;
    //transition: opacity .5s ease-out;

    &.tablet {
      font-size: 1.75rem;
      padding-left: 5rem;
      padding-right: 2rem;

      height: 4rem;
      margin-left: 6rem;
      margin-top: 4rem;
    }

    &.mobile {
      font-size: 1rem;
      padding-left: 3rem;
      padding-right: 1rem;

      height: 3rem;
      margin-left: 4rem;
      margin-top: 2.5rem;
    }
  }

  .description {
    pointer-events: auto;
    overflow-y: auto;

    width: 33%;
    height: 50%;

    color: $button-text-color;
    background-color: rgba($button-background-normal, .75);
    //box-shadow: 0 0 10px 5px #fff;

    position: absolute;
    left: unset;
    bottom: unset;
    right: 0;
    top: 50%;
    transform: translateY(-50%); // center vertically

    font-size: 1.25rem;
    font-weight: 200;
    padding: 1rem 2rem;
    border-radius: 1.5rem 0 0 1.5rem;

    //opacity: 1;
    //transition: opacity .5s ease-out;

    .description-title {
      font-size: 120%;
      font-weight: 700;
      margin-bottom: 1rem;
      //clear: both;
    }

    img {
      width: 100%;
    }

    &.tablet {
      font-size: 1rem;
      padding: .75rem 1.5rem;
      border-radius: 1.125rem 0 0 1.125rem;
    }

    &.mobile {
      font-size: .8rem;
      padding: .75rem 1rem;
      border-radius: .75rem 0 0 .75rem;
    }
  }
}

@media (orientation: portrait) {
  .description {

    .description {
      width: 50%;
      height: 25%;

      right: unset;
      left: 50%;
      top: unset;
      transform: translateX(-50%);
      bottom: 0;

      border-radius: 1.5rem 1.5rem 0 0;

      &.tablet {
        border-radius: 1.125rem 1.125rem 0 0;
      }

      &.mobile {
        border-radius: .75rem .75rem 0 0;
      }
    }
  }
}