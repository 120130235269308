.touch-to-continue {
  text-align: center;
  pointer-events: auto;

  .icon {
    width: 6rem;
    height: 6rem;
    margin-bottom: 2rem;

    &.mobile {
      width: 4rem;
      height: 4rem;
    }
  }

  .text {
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 2.5rem;

    &.mobile {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}