@import 'src/styles/shared';

.navigation {
  pointer-events: none;

  .navigation-button-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;

    .navigation-button {
      padding: 0;
      width: 7.5rem;
      height: 4.5rem;
      margin: 0 .25rem;

      // box
      border-radius: 1.5rem 1.5rem 0 0;
      box-shadow: none;
      border: 0;
      cursor: pointer;
      pointer-events: auto;

      // text
      font-family: inherit;
      text-transform: uppercase;
      color: $button-text-color;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $button-background-normal;
      transition: background-color .5s ease-out, opacity .5s ease-out;
      transition-duration: .5s;

      &:active {
        background-color: $button-background-active;
        transition-duration: 0s;
      }

      &.tablet {
        width: 5.5rem;
        height: 3.5rem;
        margin: 0 .175rem;

        border-radius: 1.125rem 1.125rem 0 0;
        font-size: 1.125rem;
      }

      &.mobile {
        width: 4rem;
        height: 2.5rem;
        margin: 0 .125rem;
        border-radius: .75rem .75rem 0 0;
        font-size: .85rem;
      }

      & > * {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .navigation {
    .navigation-button-container {
      .navigation-button {
        &:hover {
          background-color: $button-background-hover;
          transition-duration: .2s;
        }

        &:active {
          background-color: $button-background-active;
          transition-duration: 0s;
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .navigation {
    .navigation-button-container {
      bottom: 50%;
      left: 100%;
      transform: translateX(-100%) translateY(50%);

      flex-direction: column;

      .navigation-button {
        margin: .25rem 0;
        border-radius: 1.5rem 0 0 1.5rem;

        &.tablet {
          margin: .175rem 0;
          border-radius: 1.125rem 0 0 1.125rem;
        }

        &.mobile {
          margin: .125rem 0;
          border-radius: .75rem 0 0 .75rem;
        }
      }
    }
  }
}
