@import 'src/styles/shared';

.logo-ceva {
  position: absolute;
  z-index: 900;
  pointer-events: none;
  user-select: none;

  top: 0;
  left: 0;

  opacity: 1;
  padding: 1rem;

  transition: opacity .5s ease-out;

  img {
    width: 12rem;

    &.tablet {
      width: 10rem;
    }

    &.mobile {
      width: 6rem;
    }
  }
}